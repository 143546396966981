import classNames from "classnames";
import type { HTMLAttributes, ReactNode } from "react";

export type NavLinkProps = {
  href: string;
  target?: string;
  children: React.ReactNode;
  variant: "light" | "dark";
  icon?: ReactNode;
  active?: boolean;
} & HTMLAttributes<HTMLAnchorElement>;

export function NavLink({
  children,
  variant,
  icon = null,
  active,
  ...props
}: NavLinkProps) {
  return (
    <a
      {...props}
      className={classNames(
        "flex flex-row gap-2 justify-center items-center inline-block rounded-lg px-2 py-1 text-sm hover:bg-eggplant-100 hover:text-eggplant-600",
        !active && variant === "dark" && "text-eggplant-50",
        !active && variant === "light" && "text-slate-700",
        active && variant == "dark" && "bg-eggplant-100 text-eggplant-600",
        active && variant == "light" && "bg-eggplant-400 text-eggplant-50"
      )}
    >
      {children}
      {icon && <span>{icon}</span>}
    </a>
  );
}
