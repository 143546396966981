import classNames from "classnames";

export type LogoProps = {
  variant: "light" | "dark";
  className: string;
};

export function Logo({ variant, className }: LogoProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      className={classNames(
        "h-8 w-auto",
        variant == "dark" ? "fill-white" : "fill-black",
        className
      )}
      viewBox="0 0 89 28"
    >
      <g clipPath="url(#clip0_333_37312)">
        <path
          fill="url(#paint0_linear_333_37312)"
          d="M27.998 6.033c0 1.951-2.247 3.681-5.71 4.748-.766.234-1.293.975-1.293 1.795v5.58c-1.606-.39-3.413-.65-5.346-.755-.916-.039-1.656-.832-1.656-1.782V1.793c0-.924.74-1.665 1.631-1.626 6.965.351 12.374 2.836 12.374 5.866z"
        ></path>
        <path
          fill="url(#paint1_radial_333_37312)"
          d="M27.998 6.033c0 1.951-2.247 3.681-5.71 4.748-.766.234-1.293.975-1.293 1.795v5.58c-1.606-.39-3.413-.65-5.346-.755-.916-.039-1.656-.832-1.656-1.782V1.793c0-.924.74-1.665 1.631-1.626 6.965.351 12.374 2.836 12.374 5.866z"
        ></path>
        <path
          fill="url(#paint2_linear_333_37312)"
          d="M3.501 3.34v6.582C1.318 8.883 0 7.53 0 6.034c0-1.235.891-2.367 2.422-3.316.477-.3 1.08.052 1.08.624z"
        ></path>
        <path
          fill="url(#paint3_linear_333_37312)"
          d="M0 23.255V6.033C0 7.53 1.318 8.895 3.501 9.935c.665.313 1.406.599 2.222.859.765.234 1.28.975 1.28 1.795v14.399c0 .715-.665 1.222-1.33 1.014A18.39 18.39 0 013.5 27.17C1.318 26.116 0 24.75 0 23.255z"
        ></path>
        <path
          fill="url(#paint4_linear_333_37312)"
          d="M27.998 23.255V6.033c0 1.951-2.246 3.681-5.71 4.748-.766.234-1.293.975-1.293 1.795V26.975c0 .715.665 1.222 1.33 1.014 3.44-1.066 5.673-2.796 5.673-4.734z"
        ></path>
        <path
          fill="url(#paint5_linear_333_37312)"
          d="M0 23.255V6.033C0 7.53 1.318 8.895 3.501 9.935v8.273c0 .676-.351 1.3-.916 1.639C.954 20.809 0 21.98 0 23.255z"
        ></path>
        <path
          fill="url(#paint6_linear_333_37312)"
          d="M27.998 6.033c0 1.951-2.246 3.681-5.71 4.748-.766.234-1.293.975-1.293 1.795v5.58c4.18 1.014 7.003 2.926 7.003 5.099V6.033z"
        ></path>
        <path
          fill="#78FFFF"
          d="M7.003 26.975a518.13 518.13 0 01-.088-7.649l-.025-3.824-.013-1.912v-.962c0-.156-.012-.3-.05-.442a2.337 2.337 0 00-.15-.417 1.744 1.744 0 00-.565-.65 1.832 1.832 0 00-.39-.195l-.439-.143a15.195 15.195 0 01-1.744-.69c-.565-.273-1.117-.572-1.631-.95A5.133 5.133 0 01.565 7.803a3.808 3.808 0 01-.427-.884c-.1-.312-.15-.65-.138-.989.025-.676.289-1.314.678-1.834.389-.52.878-.936 1.405-1.288.063-.039.126-.09.201-.13l.1-.065c.038-.026.076-.039.113-.052A.742.742 0 013 2.547c.327.117.54.456.502.78a.717.717 0 00-.54-.637.608.608 0 00-.4.04c-.026.012-.064.025-.089.051l-.088.078c-.062.04-.125.092-.188.13-.502.352-.966.755-1.33 1.262-.364.481-.602 1.08-.615 1.691-.013.3.038.611.125.898.088.286.226.559.402.82.339.506.803.923 1.293 1.287.49.364 1.029.65 1.593.91.553.26 1.13.482 1.707.677l.44.143c.163.052.313.13.451.22.276.183.502.456.653.755.075.156.138.312.175.482.038.169.05.338.05.507v.962l-.012 1.912-.025 3.825c-.025 2.536-.05 5.085-.1 7.635zM14.005 1.793c-.037-.442.126-.91.44-1.236.313-.325.765-.52 1.217-.507.853.039 1.707.117 2.547.221 1.695.221 3.376.585 4.995 1.184.803.299 1.594.663 2.334 1.118.364.234.728.482 1.055.78.326.3.627.625.878 1.002.238.377.427.807.49 1.275.062.455.012.936-.151 1.366-.15.429-.402.82-.69 1.157a5.825 5.825 0 01-.967.898c-.702.533-1.48.923-2.271 1.261-.402.17-.803.313-1.205.456l-.301.104c-.1.039-.188.065-.289.104a1.897 1.897 0 00-.49.351c-.275.286-.463.69-.501 1.106l-.013.156v3.797l-.037 5.295-.05 5.294-.05-5.294-.038-5.294-.013-2.64V12.42c.038-.469.226-.924.552-1.249.163-.17.352-.3.552-.403.1-.052.214-.091.314-.117l.301-.104c.402-.13.803-.287 1.193-.456.778-.338 1.53-.728 2.208-1.235.34-.26.653-.547.929-.859.276-.325.502-.69.64-1.08.138-.39.188-.819.125-1.235a2.965 2.965 0 00-.451-1.158 4.876 4.876 0 00-.829-.95 7.165 7.165 0 00-1.016-.754c-.715-.455-1.494-.806-2.284-1.105-1.594-.598-3.25-.963-4.932-1.184a27.406 27.406 0 00-2.535-.234c-.2-.013-.402 0-.59.065-.188.065-.364.17-.514.312-.151.143-.29.312-.377.495-.1.195-.163.403-.176.624z"
          className="text"
        ></path>
        <path
          fillRule="evenodd"
          d="M47.398 4.72h-4.514v18.275h4.514V4.72zm41.448 5.007a5.982 5.982 0 00-.487-1.416c-.218-.46-.5-.92-.834-1.342a5.498 5.498 0 00-1.256-1.155 6.194 6.194 0 00-1.693-.795c-.629-.199-1.347-.298-2.129-.298h-7.194c-.5 0-1.052.087-1.642.26a5.128 5.128 0 00-1.667.845c-.513.398-.95.92-1.295 1.54-.347.647-.526 1.417-.526 2.311v13.305h4.514v-4.174h7.823c.436 0 .898-.05 1.385-.149a7.021 7.021 0 002.847-1.267 5.763 5.763 0 001.192-1.217c.347-.473.616-1.032.821-1.64.205-.609.295-1.317.295-2.075V11.07c0-.41-.051-.87-.154-1.342zm-4.36 2.746c0 .31-.051.583-.141.82-.09.223-.23.422-.398.57-.18.162-.384.3-.64.398-.257.1-.552.174-.886.2H74.65V9.69c0-.224.051-.385.14-.472.116-.112.334-.137.501-.137h7.169c.32 0 .603.05.846.15.231.099.423.223.59.384.167.162.295.373.398.621.102.261.18.547.205.87v1.367h-.013zm-29.38 6.012c.115.112.333.137.5.137h12.298v4.373h-12.31a5.716 5.716 0 01-1.642-.261 5.416 5.416 0 01-1.668-.857c-.525-.386-.961-.907-1.295-1.54-.346-.647-.526-1.417-.526-2.311V4.72h4.514v13.292c0 .224.039.385.129.472z"
          className="text"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_333_37312"
          x1="12.506"
          x2="25.491"
          y1="14.809"
          y2="2.28"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B65DF6"></stop>
          <stop offset="1" stopColor="#600CD4"></stop>
        </linearGradient>
        <radialGradient
          id="paint1_radial_333_37312"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(11.599 0 0 12.0219 14.748 .96)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3CAEFF"></stop>
          <stop offset="1" stopColor="#3CAEFF" stopOpacity="0"></stop>
        </radialGradient>
        <linearGradient
          id="paint2_linear_333_37312"
          x1="1.339"
          x2="3.246"
          y1="8.821"
          y2="4.173"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#600CD4"></stop>
          <stop offset="1" stopColor="#318CEA"></stop>
        </linearGradient>
        <linearGradient
          id="paint3_linear_333_37312"
          x1="1.978"
          x2="5.898"
          y1="11.701"
          y2="26.67"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#49E3E8"></stop>
          <stop offset="0.994" stopColor="#318CEA"></stop>
        </linearGradient>
        <linearGradient
          id="paint4_linear_333_37312"
          x1="21.698"
          x2="26.627"
          y1="27.355"
          y2="11.94"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.006" stopColor="#318CEA"></stop>
          <stop offset="1" stopColor="#49E3E8"></stop>
        </linearGradient>
        <linearGradient
          id="paint5_linear_333_37312"
          x1="0.003"
          x2="3.501"
          y1="14.641"
          y2="14.641"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3CAEFF" stopOpacity="0"></stop>
          <stop offset="1" stopColor="#365AFF" stopOpacity="0.7"></stop>
        </linearGradient>
        <linearGradient
          id="paint6_linear_333_37312"
          x1="20.995"
          x2="27.993"
          y1="14.641"
          y2="14.641"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#365AFF" stopOpacity="0.7"></stop>
          <stop offset="1" stopColor="#3CAEFF" stopOpacity="0"></stop>
        </linearGradient>
        <clipPath id="clip0_333_37312">
          <path fill="#fff" d="M0 0H89V28H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}
